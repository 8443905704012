<template lang="pug">
  div(id="terms-privacy").terms-privacy
    div.container
      div.terms-privacy__wrapper
        h1 Privacy Policy
        div.terms-privacy__inner
          div.terms-privacy__block
            h3 Privacy Policy
            p Avalon Soft OÜ built the Weather Forecast & Live Radar app as an Ad Supported app. This SERVICE is provided by Avalon Soft OÜ at no cost and is intended for use as is.
            p This page is used to inform visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service.
            p If you choose to use our Service, then you agree to the collection and use of information in relation to this policy. The Personal Information that we collect is used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy.
            p The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which are accessible at Weather Forecast & Live Radar unless otherwise defined in this Privacy Policy.
          div.terms-privacy__block
            h3 Information Collection and Use
            p For a better experience, while using our Service, we may require you to provide us with certain personally identifiable information. The information that we request will be retained by us and used as described in this privacy policy.
            p The app does use third-party services that may collect information used to identify you.
            p Link to the privacy policy of third-party service providers used by the app
            ul
              li
                a(href="https://policies.google.com/terms" target="_blank") Google Play Services
              li
                a(href="https://developers.google.com/admob/terms" target="_blank") AdMob
              li
                a(href="https://www.google.com/analytics/terms/" target="_blank") Google Analytics for Firebase
              li
                a(href="https://firebase.google.com/terms/crashlytics" target="_blank") Firebase Crashlytics
              li
                a(href="https://www.facebook.com/legal/terms/plain_text_terms" target="_blank") Facebook
          div.terms-privacy__block
            h3 Log Data
            p We want to inform you that whenever you use our Service, in a case of an error in the app we collect data and information (through third-party products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol (“IP”) address, device name, operating system version, the configuration of the app when utilizing our Service, the time and date of your use of the Service, and other statistics.
          div.terms-privacy__block
            h3 Cookies
            p Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and are stored on your device's internal memory.
            p This Service does not use these “cookies” explicitly. However, the app may use third-party code and libraries that use “cookies” to collect information and improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service.
          div.terms-privacy__block
            h3 Service Providers
            p We may employ third-party companies and individuals due to the following reasons:
            ul
              li To facilitate our Service;
              li To provide the Service on our behalf;
              li To perform Service-related services; or
              li To assist us in analyzing how our Service is used.
          p We want to inform users of this Service that these third parties have access to their Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.
          div.terms-privacy__block
            h3 Security
            p We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.
          div.terms-privacy__block
            h3 Links to Other Sites
            p This Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.
          div.terms-privacy__block
            h3 Children’s Privacy
            p These Services do not address anyone under the age of 13. We do not knowingly collect personally identifiable information from children under 13 years of age. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do the necessary actions.
          div.terms-privacy__block
            h3 Changes to This Privacy Policy
            p We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page.
            p This policy is effective as of 2024-01-19
          div.terms-privacy__block
            h3 Contact Us
            p If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at
              a(href="mailto:weather@avalonsofts.com") weather@avalonsofts.com.

</template>

<script>
export default {
  name: 'PolicyPage'
}
</script>
